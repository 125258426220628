import { useEffect, useState } from "react";
import { updateWorkerPassword } from "../functions/WorkersQueries";

const CambiarPassword = ({id, setNuevoPassword, setRefrescar}) => {

    const [data, setData] = useState({
		passwd: "",
		repeatpasswd: "",
	});
	const [respuesta, setRespuesta] = useState('')
	const submitForm = (e) => {
		data.id = id;
		e.preventDefault();
		if(data.passwd !== data.repeatpasswd){
			alert("Las contraseñas no coinciden");
			return;
		}
		const regex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
		if(!regex.test(data.passwd)){
			alert("La contraseña debe ser de por lo menos 8 caracteres, y contener al menos una letra mayúscula, una minúscula y un número.");
			return;
		}
		updateWorkerPassword(data, setRespuesta);
	};

	useEffect(() => {
		if (respuesta === "success") {
			alert("Contraseña actualizada con éxito");
			setNuevoPassword(0);
			setRefrescar(1);
		}else if (respuesta === "error"){
			alert("Error al actualizar la contraseña. Intente con otra contraseña");
			setNuevoPassword(0);
		}
	}, [respuesta, setNuevoPassword, setRefrescar])

    const updateField = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

    	// Disable ignore enter
	useEffect(() => {
		const listener = (event) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				alert(
					"Asegúrese de llenar los datos marcados con asterisco y después de click en el botón Guardar"
				);
				event.preventDefault();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, []);
    

  return (
    <>
		<div className="mt-6 text-xl font-semibold text-center text-sky-700">
			Cambiar la contraseña del usuario con ID {id}:
		</div>
		<div className="mt-6 text-l font-semibold text-center text-sky-700">
			Las contraseñas deben ser de por lo menos 8 caracteres, y contener al menos una letra mayúscula, una minúscula y un número.
		</div>

		<form method="POST" onSubmit={submitForm}>
			<div className="flex flex-row items-center max-w-full my-2">
				<div className="w-1/4 text-center mx-9">
					<span>Nueva contraseña *</span>
				</div>
				<input
					type="text"
					name="passwd"
					id="passwd"
					onChange={updateField}
					required
					className="w-3/4 p-1 mr-3 border rounded border-slate-400"
				/>
			</div>
			<div className="flex flex-row items-center max-w-full my-2">
				<div className="w-1/4 text-center mx-9">
					<span>Repita la nueva contraseña *</span>
				</div>
				<input
					type="text"
					name="repeatpasswd"
					id="repeatpasswd"
					onChange={updateField}
					required
					className="w-3/4 p-1 mr-3 border rounded border-slate-400"
				/>
			</div>
			<div className="flex flex-row items-center justify-center mt-4 mb-16">
				<button
					className="px-3 py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
					onClick={() => setNuevoPassword(0)}
				>
					Cancelar
				</button>
				<button
					className="px-3 py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
					type="submit"
					onKeyPress={submitForm}
				>
					Guardar
				</button>
			</div>
        </form>
	</>
)
}

export default CambiarPassword