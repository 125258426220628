import { useState } from "react";
import Compressor from "compressorjs";
import { baseurl } from "../functions/constants";
import axios from "axios";

const GuardarIne = ({ id_usuario, setCapturarIne, tipoCaptura }) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [id, setId] = useState(id_usuario);
	const [tipoC, setTipoC] = useState(tipoCaptura);
	// const [compressedFile, setCompressedFile] = useState(null);

	const onFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const onFileUpload = () => {
		if (selectedFile) {
			new Compressor(selectedFile, {
				quality: 0.5,
				success: (compressedResult) => {
					const formData = new FormData();
					formData.append("image", compressedResult, selectedFile.name);
					formData.append("id", id);
					formData.append("tipoCaptura", tipoC);
					const url = `${baseurl}/captureIne`;
					axios
						.post(url, formData)
						.then((response) => {
							if (response.status === 200) {
								alert("Imagen guardada");
							} else {
								alert("Error al guardar imagen");
							}
							setSelectedFile(null);
							cancelIne();
						})
						.catch((error) => {
							console.error("error", error);
						});
				},
			});
		} else {
			alert("Seleccione una imágen válida");
		}
	};

	const cancelIne = () => {
		setCapturarIne(0);
		setId("");
		setTipoC("");
	};

	const fileData = () => {
		if (selectedFile) {
			return (
				<div className="flex flex-col justify-center w-full h-full mt-6">
					<div className="text-center ">
						<div className="text-xl">Detalles:</div>
						<div>Nombre del archivo: {selectedFile.name}</div>
						<div>Tipo: {selectedFile.type}</div>
					</div>
				</div>
			);
		}
	};

	return (
		<div className="px-4 py-5 mx-4 mb-8 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="text-xl text-center">Captura de imágenes</div>
			<div className="text-xl text-center">{id_usuario}</div>
			<br />
			<div className="mx-2">
				<label
					className="block mb-2 text-sm font-medium text-gray-900 "
					htmlFor="file_input"
				>
					Seleccione la imagen (solo JPG o PNG)
				</label>
				<input
					className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
					onChange={onFileChange}
					id="file_input"
					type="file"
					accept="image/png, image/jpeg"
				/>
			</div>
			{fileData()}
			<div className="flex flex-row justify-center w-full h-full mt-6">
				<button
					onClick={cancelIne}
					className={
						selectedFile
							? "px-3 text-sm py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
							: "px-3 text-sm py-1 text-gray-100 bg-gray-500 shadow-lg rounded-xl text-light mx-3"
					}
				>
					Cancelar
				</button>
				<button
					onClick={() => onFileUpload()}
					className={
						selectedFile
							? "px-3 text-sm py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
							: "px-3 text-sm py-1 text-gray-100 bg-gray-500 shadow-lg rounded-xl text-light mx-3"
					}
				>
					Cargar archivo
				</button>
			</div>
		</div>
	);
};

export default GuardarIne;
