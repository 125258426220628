import { useState, useEffect } from "react";
import {
  createPermissionsNewWorker,
  createWorker,
} from "../functions/WorkersQueries";

const NuevoTrabajador = ({ setNuevoTrabajador, setRefrescar }) => {
  const [data, setData] = useState({
    nombre: "",
    username: "",
    curp: "",
    password: "",
    permiso_altas: "",
    permiso_bajas: "",
    permiso_modificaciones: "",
    permiso_reestructuras: "",
    permiso_recompras: "",
    permiso_estados_cuenta: "",
    permisos_administracion_creditos_activos: "",
    boton_retencion_con_intereses: "",
    boton_retencion_sin_intereses: "",
    analisis_cobranza: "",
    reporte_colocacion: "",
  });
  const submitForm = (e) => {
    e.preventDefault();
    const regex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!regex.test(data.password)) {
      alert(
        "La contraseña debe ser de por lo menos 8 caracteres, y contener al menos una letra mayúscula, una minúscula y un número."
      );
      return;
    }
    createWorker(data, setNuevoTrabajador);
    createPermissionsNewWorker(data);
    regresar();
  };

  const regresar = () => {
    setTimeout(() => {
      setRefrescar(1);
    }, 1000);
  };

  const updateField = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // Disable ignore enter
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        alert(
          "Asegúrese de llenar los datos marcados con asterisco y después de click en el botón Guardar"
        );
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <div className="mt-6 text-xl font-semibold text-center text-sky-700">
        Nuevo Trabajador:
      </div>

      <form method="POST" onSubmit={submitForm}>
        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>Nombre *</span>
          </div>
          <input
            type="text"
            name="nombre"
            id="nombre"
            onChange={updateField}
            required
            className="w-3/5 p-1 mr-3 border rounded border-slate-400"
          />
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>Nombre de usuario *</span>
          </div>
          <input
            type="text"
            name="username"
            id="username"
            onChange={updateField}
            required
            className="w-3/5 p-1 mr-3 border rounded border-slate-400"
          />
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>CURP *</span>
          </div>
          <input
            type="text"
            name="curp"
            id="curp"
            onChange={updateField}
            required
            minLength="18"
            maxLength="18"
            className="w-3/5 p-1 mr-3 border rounded border-slate-400"
          />
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>Contraseña *</span>
          </div>
          <input
            type="password"
            name="password"
            id="password"
            onChange={updateField}
            required
            className="w-3/5 p-1 mr-3 border rounded border-slate-400"
          />
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-2/5 text-center mx-9">
            <span>Permiso de altas *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_altas"
              id="permiso_altas"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>Permiso de bajas *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_bajas"
              id="permiso_bajas"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-2/5 text-center mx-9">
            <span>Permiso de reestructuras *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_reestructuras"
              id="permiso_reestructuras"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>Permiso de recompras *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_recompras"
              id="permiso_recompras"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-2/5 text-center mx-9">
            <span>Permiso de administración de créditos activos *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="permisos_administracion_creditos_activos"
              id="permisos_administracion_creditos_activos"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>Estados de Cuenta *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_estados_cuenta"
              id="permiso_estados_cuenta"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-2/5 text-center mx-9">
            <span>Retención con intereses *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="boton_retencion_con_intereses"
              id="boton_retencion_con_intereses"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 ">
          <div className="w-2/5 text-center mx-9">
            <span>Retención sin intereses *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="boton_retencion_sin_intereses"
              id="boton_retencion_sin_intereses"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-2/5 text-center mx-9">
            <span>Análisis de cobranza *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="analisis_cobranza"
              id="analisis_cobranza"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-2/5 text-center mx-9">
            <span>Reporte de colocación *</span>
          </div>
          <div className="w-3/5 p-1 mr-3">
            <input
              type="checkbox"
              name="reporte_colocacion"
              id="reporte_colocacion"
              className="w-5 h-5 form-checkbox text-sky-600"
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center justify-center mt-4 mb-16">
          <button
            className="px-3 py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
            onClick={() => setNuevoTrabajador(0)}
          >
            Cancelar
          </button>
          <button
            className="px-3 py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
            type="submit"
            onKeyPress={submitForm}
          >
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};

export default NuevoTrabajador;
