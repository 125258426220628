import { baseurl } from "./constants";

export const checkIfLogged = (setLogged) => {
	const jwt = JSON.parse(localStorage.getItem("jwt"));
	if (typeof jwt === "undefined" || jwt === null) {
		setLogged(false);
		localStorage.removeItem("jwt");
		localStorage.removeItem("user_id");
		localStorage.removeItem("name");
	} else {
		setLogged(true);
	}
};

export const login = async (
	username,
	passwd,
	setLogged,
	setError,
	setErrorMsg,
	setConsultado,
	setLoading
) => {
	let raw = JSON.stringify({
		username: `${username}`,
		password: `${passwd}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};

	const url = `${baseurl}/login`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			setConsultado(false);
			if (result.length > 0) {
				localStorage.setItem("jwt", JSON.stringify(result));
				localStorage.setItem("username", JSON.stringify(username));
				setLogged(true);
				setLoading(false);
			} else {
				setLogged(false);
				setError(true);
				setErrorMsg("Nombre de usuario o contraseña equivocados");
				setLoading(false);
				return;
			}
		})
		.catch((error) => {
			console.error(error);
		});
};

export const logout = (setLogged) => {
	try {
		setLogged(false);
		localStorage.removeItem("jwt");
		localStorage.removeItem("username");
		window.location.href = "/";
	} catch (error) {
		console.error(error);
	}
};

export const getUserData = async (setUserPermissions) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	const username = JSON.parse(localStorage.getItem("username"));
	let raw = JSON.stringify({
		token: `${token}`,
		username: `${username}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors"
	};
	const url = `${baseurl}/userData`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			setUserPermissions(result[0]);
		})
		.catch((error) => console.error(error));
}