import { baseurl } from "./constants";

export const getWorkers = async (clave_organizacion, callback) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", "true");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    cors: "no-cors",
  };
  const url = `${baseurl}/getWorkers/${clave_organizacion}`;
  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((responseJSON) => {
      callback(responseJSON);
    })
    .catch((error) => console.error(error));
};

export const createWorker = async (data, setNuevoTrabajador) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  const username = JSON.parse(localStorage.getItem("username"));
  let raw = JSON.stringify({
    token: `${token}`,
    representante: `${username}`,
    nombre: `${data.nombre}`,
    username: `${data.username}`,
    curp: `${data.curp}`,
    password: `${data.password}`,
    permiso_altas: `${data.permiso_altas}`,
    permiso_bajas: `${data.permiso_bajas}`,
    permiso_modificaciones: `${data.permiso_modificaciones}`,
    permiso_reestructuras: `${data.permiso_reestructuras}`,
    permiso_recompras: `${data.permiso_recompras}`,
    permiso_estados_cuenta: `${data.permiso_estados_cuenta}`,
    permisos_administracion_creditos_activos: `${data.permisos_administracion_creditos_activos}`,
    boton_retencion_con_intereses: `${data.boton_retencion_con_intereses}`,
    boton_retencion_sin_intereses: `${data.boton_retencion_sin_intereses}`,
    analisis_cobranza: `${data.analisis_cobranza}`,
    reporte_colocacion: `${data.reporte_colocacion}`,
  });
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    cors: "no-cors",
  };
  const url = `${baseurl}/createWorker`;
  await fetch(url, requestOptions)
    .then((response) => response.json())
    .then(setNuevoTrabajador(0))
    .catch((error) => console.error(error));
};

export const updateWorkerPassword = async (data, respuesta) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let raw = JSON.stringify({
    token: `${token}`,
    id: `${data.id}`,
    password: `${data.passwd}`,
  });
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    cors: "no-cors",
  };
  const url = `${baseurl}/updateWorkerPassword`;
  await fetch(url, requestOptions)
    .then((response) => response.json())
    .then(respuesta)
    .catch((error) => console.error(error));
};

export const createPermissionsNewWorker = async (data) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let raw = JSON.stringify({
    nombre: `${data.nombre}`,
    username: `${data.username}`,
    password: `${data.password}`,
  });
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    cors: "no-cors",
  };
  const url = `${baseurl}/createPermissionsNewWorker`;
  setTimeout(() => {
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((responseJSON) => {
        console.log(responseJSON[0]);
      })
      .catch((error) => console.error(error));
  }, 2000);
};

export const activateWorker = async (id_usuario, setRefrescar) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", "true");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    cors: "no-cors",
  };
  const url = `${baseurl}/activateWorker/${id_usuario}`;
  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then(setRefrescar(1))
    .catch((error) => console.error(error));
};

export const permissionsSentToMC = async (id_usuario, setRefrescar) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", "true");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    cors: "no-cors",
  };
  const url = `${baseurl}/permissionsSentToMC/${id_usuario}`;
  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then(setRefrescar(1))
    .catch((error) => console.error(error));
};

export const countPermissionsWorker = async (id_usuario, callback) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", "true");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    cors: "no-cors",
  };
  const url = `${baseurl}/countPermissionsWorker/${id_usuario}`;
  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((responseJSON) => {
      callback(responseJSON[0]);
    })
    .catch((error) => console.error(error));
};

export const datosWorker = async (id, callback) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", "true");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    cors: "no-cors",
  };
  const url = `${baseurl}/datosWorker/${id}`;
  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((responseJSON) => {
      callback(responseJSON[0]);
    })
    .catch((error) => console.error(error));
};

export const updateWorker = async (data) => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  let raw = JSON.stringify({
    token: `${token}`,
    id_usuario: `${data.id_usuario}`,
    permiso_altas: `${data.permiso_altas}`,
    permiso_bajas: `${data.permiso_bajas}`,
    permiso_modificaciones: `${data.permiso_modificaciones}`,
    permiso_reestructuras: `${data.permiso_reestructuras}`,
    permiso_recompras: `${data.permiso_recompras}`,
    permiso_estados_cuenta: `${data.permiso_estados_cuenta}`,
    permisos_administracion_creditos_activos: `${data.permisos_administracion_creditos_activos}`,
    boton_retencion_con_intereses: `${data.boton_retencion_con_intereses}`,
    boton_retencion_sin_intereses: `${data.boton_retencion_sin_intereses}`,
    analisis_cobranza: `${data.analisis_cobranza}`,
    reporte_colocacion: `${data.reporte_colocacion}`,
  });
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    cors: "no-cors",
  };
  const url = `${baseurl}/updateWorker`;
  await fetch(url, requestOptions)
    .then((response) => response.json())
    // .then(setNuevoTrabajador(0))
    .catch((error) => console.error(error));
};
