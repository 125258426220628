import { activateWorker, permissionsSentToMC } from "../functions/WorkersQueries";
import MostrarSiNo from "./MostrarSiNo";

const TrabajadoresLista = ({
	trabajadores,
	setRefrescar,
	setNuevoTrabajador,
	setCapturarIne,
	setTipoCaptura,
	setVerDatosWorker,
	setWorker,
	setVerPermisos,
	setNuevoPassword,
}) => {
	const getIne = (id_usuario, tipo) => {
		setNuevoTrabajador(0);
		setVerDatosWorker(0);
		setCapturarIne(id_usuario);
		setTipoCaptura(tipo);
	};

	const verIne = (id_usuario) => {
		setNuevoTrabajador(0);
		setVerDatosWorker(1);
		setWorker(id_usuario);
	};

	const actualizarPwd = (id_usuario) => {
		setWorker(id_usuario);
		setNuevoPassword(1);
	};

	let index = 0;

	return (
		<>
			<div className="mt-6 text-xl font-semibold text-center text-sky-700">
				Trabajadores:
			</div>
			<div className="flex flex-col items-center justify-center m-4 w-9/10">
				{trabajadores.length ? (
					<>
						<div className="mt-8 -mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr key={index++}>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Nombre
										</th>
										<th
											scope="col"
											className="hidden px-3 py-2 text-xs font-semibold text-center text-gray-900 sm:table-cell"
										>
											Usuario
										</th>
										<th
											scope="col"
											className="hidden px-3 py-2 text-xs font-semibold text-center text-gray-900 lg:table-cell"
										>
											CURP
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Activo
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Autorizado
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Capturar <br />/<br /> Actualizar INE
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Ver INE
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Activar / desactivar
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Permiso para enviar
											<br />a Mesa de Control
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Permisos
										</th>
										<th
											scope="col"
											className="px-3 py-2 text-xs font-semibold text-center text-gray-900"
										>
											Contraseña
										</th>
									</tr>
								</thead>
								{trabajadores.map((item) => {
									return (
										<tbody className="bg-white divide-y divide-gray-200">
											<tr key={index++}>
												<td className="px-3 py-2 text-xs text-center text-gray-900">
													{item.nombre}
												</td>
												<td className="hidden px-3 py-2 text-xs text-center text-gray-900 sm:table-cell">
													{item.username}
												</td>
												<td className="hidden px-3 py-2 text-xs text-center text-gray-900 lg:table-cell">
													{item.curp}
												</td>
												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<MostrarSiNo valor={item.activo} />
												</td>
												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<MostrarSiNo valor={item.autorizado} />
												</td>
												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<div className="flex flex-row items-center justify-center mx-2">
														<button
															className="px-3 py-1 mx-1 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
															onClick={() => getIne(item.id_usuario, "anverso")}
														>
															Anverso
														</button>
														<button
															className="px-3 py-1 mx-1 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
															onClick={() => getIne(item.id_usuario, "reverso")}
														>
															Reverso
														</button>
													</div>
												</td>
												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<button
														className="px-3 py-1 mx-auto text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
														onClick={() => verIne(item.id_usuario)}
													>
														Ver
													</button>
												</td>

												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<button
														className="px-3 py-1 mx-auto text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
														onClick={() =>
															activateWorker(item.id_usuario, setRefrescar)
														}
													>
														{item.activo === 1 ? "Desactivar" : "Activar"}
													</button>
												</td>

												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<button
														className="px-3 py-1 mx-auto text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
														onClick={() =>
															permissionsSentToMC(item.id_usuario, setRefrescar)
														}
													>
														{item.permiso_enviar_mesa_control === 1 ? "Desactivar" : "Activar"}
													</button>
												</td>

												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<button
														className="px-3 py-1 mx-auto text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
														onClick={() => setVerPermisos(item.id_usuario)}
													>
														Permisos
													</button>
												</td>
												<td className="px-3 py-2 text-xs text-center text-gray-900">
													<button
														className="px-3 py-1 mx-auto text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
														onClick={() => actualizarPwd(item.id_usuario)}
													>
														Contraseña
													</button>
												</td>
											</tr>
										</tbody>
									);
								})}
							</table>
						</div>
					</>
				) : (
					"No se encontraron trabajadores"
				)}
			</div>
		</>
	);
};

export default TrabajadoresLista;
