import chk0 from "../assets/chk0.gif";
import chk1 from "../assets/chk1.gif";

const MostrarSiNo = ({valor}) => {
	return (
		<>
			{valor ? (
				<img
					src={chk1}
					alt="Si"
					key="Si"
					className="mx-auto"
				/>
			) : (
				<img
					src={chk0}
					alt="No"
					key="No"
					className="mx-auto"
				/>	
			)}
		</>
	)
}

export default MostrarSiNo
