import React from "react";

const DetalleOrganizacion = ({ userPermissions }) => {
	return (
		<>
			<div className="text-2xl font-semibold text-center text-sky-700">
				Organización: {userPermissions?.organizacion}
			</div>

			<div className="flex flex-col items-center justify-center">
				<div className="flex flex-row items-center w-full mt-2" key="nombre">
					<div className="w-1/2 mx-2 font-semibold text-right text-gray-600">
						Representante:
					</div>
					<div className="w-1/2 mx-2 text-left text-gray-600">
						{userPermissions?.nombre}
					</div>
				</div>

				<div
					className="flex flex-row items-center w-full mt-2"
					key="clave_organizacion"
				>
					<div className="w-1/2 mx-2 font-semibold text-right text-gray-600">
						Clave:
					</div>
					<div className="w-1/2 mx-2 text-left text-gray-600">
						{userPermissions?.clave_organizacion}
					</div>
				</div>
			</div>
		</>
	);
};

export default DetalleOrganizacion;
