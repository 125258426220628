import React, { useEffect, useState } from "react";
import { getUserData } from "../functions/AuthQueries";
import { getWorkers } from "../functions/WorkersQueries";
import GuardanIne from "./GuardanIne";
import DetalleOrganizacion from "./DetalleOrganizacion";
import NuevoTrabajador from "./NuevoTrabajador";
import TrabajadoresLista from "./TrabajadoresLista";
import DatosWorker from "./DatosWorker";
import Permisos from "./Permisos";
import CambiarPassword from "./CambiarPassword";
// import TrabajadoresLista2 from "./TrabajadoresLista2";

const Organizacion = () => {
	const [userPermissions, setUserPermissions] = useState([]);
	const [trabajadores, setTrabajadores] = useState([]);
	const [refrescar, setRefrescar] = useState(0);
	const [nuevoTrabajador, setNuevoTrabajador] = useState(0);
	const [capturarIne, setCapturarIne] = useState(0);
	const [tipoCaptura, setTipoCaptura] = useState("");
	const [verDatosWorker, setVerDatosWorker] = useState(0);
	const [worker, setWorker] = useState(0);
	const [verPermisos, setVerPermisos] = useState(0);
	const [nuevoPassword, setNuevoPassword] = useState(0);

	const agregarTrabajador = () => {
		setNuevoTrabajador(1);
	};
	useEffect(() => {
		getUserData(setUserPermissions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTimeout(() => {
			getWorkers(userPermissions.clave_organizacion, setTrabajadores);
		}, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getWorkers(userPermissions.clave_organizacion, setTrabajadores);
		setRefrescar(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refrescar]);

	return (
		<>
			<DetalleOrganizacion userPermissions={userPermissions} />
			<TrabajadoresLista
				trabajadores={trabajadores}
				setRefrescar={setRefrescar}
				setNuevoTrabajador={setNuevoTrabajador}
				setCapturarIne={setCapturarIne}
				setTipoCaptura={setTipoCaptura}
				setVerDatosWorker={setVerDatosWorker}
				setWorker={setWorker}
				setVerPermisos={setVerPermisos}
				setNuevoPassword={setNuevoPassword}
			/>
			<div className="flex flex-row items-center justify-center m-4">
				<button
					className="px-3 py-1 mx-4 text-sm text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
					onClick={() => setRefrescar(1)}
				>
					Actualizar lista de trabajadores
				</button>
				<button
					className="px-3 py-1 mx-4 text-sm text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
					onClick={() => agregarTrabajador()}
				>
					Agregar trabajador
				</button>
			</div>
			{nuevoTrabajador ? (
				<NuevoTrabajador
					setNuevoTrabajador={setNuevoTrabajador}
					setRefrescar={setRefrescar}
				/>
			) : null}
			{capturarIne ? (
				<GuardanIne
					id_usuario={capturarIne}
					setCapturarIne={setCapturarIne}
					tipoCaptura={tipoCaptura}
				/>
			) : null}
			{verDatosWorker ? (
				<DatosWorker id={worker} setVerDatosWorker={setVerDatosWorker} />
			) : null}
			{verPermisos ? (
				<Permisos
					verPermisos={verPermisos}
					setVerPermisos={setVerPermisos}
					setRefrescar={setRefrescar}
					setNuevoTrabajador={setNuevoTrabajador}
				/>
			) : null}
			{nuevoPassword ? (
				<CambiarPassword id={worker} setNuevoPassword={setNuevoPassword} setRefrescar={setRefrescar} />
			) : null}
		</>
	);
};

export default Organizacion;
