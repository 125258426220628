import React, { useEffect, useState } from "react";
import { datosWorker, updateWorker } from "../functions/WorkersQueries";

const Permisos = ({
  verPermisos,
  setVerPermisos,
  setRefrescar,
  setNuevoTrabajador,
}) => {
  const [data, setData] = useState({
    permiso_altas: "",
    permiso_bajas: "",
    permiso_modificaciones: "",
    permiso_reestructuras: "",
    permiso_recompras: "",
    permiso_estados_cuenta: "",
    permisos_administracion_creditos_activos: "",
    boton_retencion_con_intereses: "",
    boton_retencion_sin_intereses: "",
    analisis_cobranza: "",
    reporte_colocacion: "",
  });

  const submitForm = (e) => {
    e.preventDefault();
    updateWorker(data);
    setNuevoTrabajador(0);
    regresar();
  };

  const updateField = (e) => {
    if (e.target.checked) {
      setData({ ...data, [e.target.name]: 1 });
    } else {
      setData({ ...data, [e.target.name]: 0 });
    }
  };

  const regresar = () => {
    setTimeout(() => {
      setRefrescar(1);
    }, 1000);
  };

  useEffect(() => {
    datosWorker(verPermisos, setData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verPermisos]);

  return (
    <>
      <div className="mt-6 text-xl font-semibold text-center text-sky-700">
        Permisos {data && `del usuario ${data.nombre}`}
      </div>

      <form method="POST" onSubmit={submitForm}>
        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-1/2 text-center mx-9">
            <span>Permiso de altas *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_altas"
              id="permiso_altas"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.permiso_altas === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-1/2 text-center mx-9">
            <span>Permiso de bajas *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_bajas"
              id="permiso_bajas"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.permiso_bajas === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-1/2 text-center mx-9">
            <span>Permiso de reestructuras *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_reestructuras"
              id="permiso_reestructuras"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.permiso_reestructuras === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-1/2 text-center mx-9">
            <span>Permiso de recompras *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_recompras"
              id="permiso_recompras"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.permiso_recompras === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-1/2 text-center mx-9">
            <span>Permiso de administración de créditos activos *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="permisos_administracion_creditos_activos"
              id="permisos_administracion_creditos_activos"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.permisos_administracion_creditos_activos === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-1/2 text-center mx-9">
            <span>Estados de cuenta *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="permiso_estados_cuenta"
              id="permiso_estados_cuenta"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.permiso_estados_cuenta === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-1/2 text-center mx-9">
            <span>Retención con intereses *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="boton_retencion_con_intereses"
              id="boton_retencion_con_intereses"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.boton_retencion_con_intereses === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-1/2 text-center mx-9">
            <span>Retención sin intereses *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="boton_retencion_sin_intereses"
              id="boton_retencion_sin_intereses"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.boton_retencion_sin_intereses === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2 bg-slate-300">
          <div className="w-1/2 text-center mx-9">
            <span>Análisis de cobranza *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="analisis_cobranza"
              id="analisis_cobranza"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.analisis_cobranza === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center max-w-full my-2">
          <div className="w-1/2 text-center mx-9">
            <span>Reporte de colocación *</span>
          </div>
          <div className="w-1/2 p-1 mr-3">
            <input
              type="checkbox"
              name="reporte_colocacion"
              id="reporte_colocacion"
              className="w-5 h-5 form-checkbox text-sky-600"
              checked={data.reporte_colocacion === 1}
              onChange={updateField}
            />
          </div>
        </div>

        <div className="flex flex-row items-center justify-center mt-4 mb-16">
          <button
            className="px-3 py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
            onClick={() => setVerPermisos()}
          >
            Cancelar
          </button>
          <button
            className="px-3 py-1 mx-4 text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
            type="submit"
            onKeyPress={submitForm}
          >
            Actualizar
          </button>
        </div>
      </form>
    </>
  );
};

export default Permisos;
