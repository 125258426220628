// get enviroment from .env file
export const enviroment = process.env.NODE_ENV;

export const baseurl =
	enviroment === "development"
		? process.env.REACT_APP_SERVER
		: process.env.REACT_APP_SERVER;

export const warningHeader = enviroment === "development" ? "Desarrollo" : "";

// 178DIGARU
// L3G4p3te6vea

// 180MABAME
// fS35pqJNBcWa