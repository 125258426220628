import { useState, useEffect } from "react";
import { baseurl } from "../functions/constants";
import { datosWorker } from "../functions/WorkersQueries";

const DatosWorker = ({ id, setVerDatosWorker }) => {
	const [worker, setWorker] = useState("");
	const [inePath1, setInePath1] = useState("");
	const [inePath2, setInePath2] = useState("");

	useEffect(() => {
		datosWorker(id, setWorker);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		setInePath1(worker.ine1 ? `${baseurl}/${worker.ine1}` : '');
		setInePath2(worker.ine2 ? `${baseurl}/${worker.ine2}` : '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [worker]);

	return (
		<>
			<div className="flex flex-row justify-center mt-8 text-xl font-semibold text-center text-sky-700">
				{worker?.nombre
					? `Identificación de ${worker.nombre}`
					: "No se encuentra identificación al respecto"}
			</div>

			<div className="h-auto max-w-full p-8 mx-auto">
				{inePath1?.length ? (
					<img
						src={inePath1}
						alt="INE anverso"
						className="border-2 rounded-xl"
					/>
				):null}
			</div>
			<hr />
			<div className="h-auto max-w-full p-8 mx-auto">
				{inePath2?.length ? (
					<img
						src={inePath2}
						alt="INE reverso"
						className="border-2 rounded-xl"
					/>
				):null}
			</div>

			<div className="flex flex-row justify-center">
				<button
					className="px-3 py-1 mx-auto text-white bg-gray-500 rounded-xl hover:bg-slate-400 hover:text-black hover:duration-500"
					onClick={() => setVerDatosWorker(0)}
				>
					Cerrar
				</button>
			</div>
		</>
	);
};

export default DatosWorker;
