import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Empty from "./components/Empty";
import Home from "./components/Home";
import Login from "./components/Login";
import Organizacion from "./components/Organizacion";
import Trabajador from "./components/Trabajador";

import { checkIfLogged } from "./functions/AuthQueries";


function App() {

	const [logged, setLogged] = useState(false);
	// checkIfLogged();
	useEffect(() => {
		checkIfLogged(setLogged);
	}, []);
	useEffect(() => {
		checkIfLogged(setLogged);
	}, [logged]);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={<Home setLogged={setLogged} logged={logged} />}
				>
					{!logged ? (
						<>
							<Route
								path="/"
								element={<Login logged={logged} setLogged={setLogged} />}
							/>
							<Route
								path="*"
								element={<Login logged={logged} setLogged={setLogged} />}
							/>
						</>
					) : (
						<>
							<Route exact path="/" element={<Navigate to="Organizacion" />} />
							<Route exact path="Organizacion" element={<Organizacion />}>
								<Route path=":organizacion_id" element={<Organizacion />} />
							</Route>
							<Route exact path="Trabajador" element={<Trabajador />}>
								<Route
									path=":organizacion_id:trabajador_id"
									element={<Trabajador />}
								/>
							</Route>
						</>
					)}
					{logged ? <Route path="*" element={<Empty />} /> : null}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
